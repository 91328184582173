/**
 *
 * This function generates a tooltip of Repayment in ZingChart
 * @param event : Event Used to get the node index
 *
 * @return object :
 * contains the parameters text which is the HTML content displayed in the tooltip.
 *
 */

const makeTooltipLastSamples = (event) => {
  const repaymentChartData = JSON.parse(
    document.getElementById("repayment_chart_data").textContent,
  );

  const nodeIndex = event.nodeindex;
  // select the proper week for tooltip
  const tooltipElement = cloneTemplateById("graph-samples-tooltip");

  const amount =
    repaymentChartData.AMOUNT[nodeIndex] ||
    repaymentChartData.CAPITAL[nodeIndex];

  const data = {
    date: repaymentChartData.WEEKS_FORMATTED[nodeIndex],
    amount: formatMoney(amount),
    payins: (repaymentChartData.TOOLTIPS[nodeIndex] ?? []).map((payin) => {
      payin.amount = formatMoney(payin.amount);
      return payin;
    }),
  };

  tooltipElement.setAttribute("x-data", JSON.stringify(data));
  return { text: tooltipElement.outerHTML };
};

const formatMoney = (amount) => {
  if (!amount && amount !== 0) return "";
  amount = Math.round(amount);
  amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${amount} €`;
};

const cloneTemplateById = (iconId) =>
  document.getElementById(iconId).content.cloneNode(true).firstElementChild;

const latestRepaymentsChart = function (repaymentChartData) {
  const configGraphLastSamples = {
    type: "bar",
    legend: null,
    scaleX: {
      item: {
        fontSize: 12,
        paddingTop: "12px",
        fontFamily: "Gilroy",
        fontWeight: "bold",
      },
      labels: repaymentChartData.DAY_AND_YEAR_FORMATTED,
      lineColor: "none",
      maxItems: 12,
      tick: {
        size: 0,
      },
    },
    scaleY: {
      rules: [
        {
          rule: "'%v' > 0",
          format: "€%v",
        },
        {
          rule: "'%v' <= 0",
          format: "%v",
        },
      ],
      item: {
        visible: false,
      },
      lineColor: "none",
      short: true,
      maxItems: 4,
      // make the largest bar reach the top of the graph
      scaleFactor: 0.01,
      tick: {
        size: 0,
      },
      guide: {
        lineColor: "#FFFDF7",
      },
    },
    crosshairX: {
      lineWidth: 0,
      plotLabel: {
        visible: false,
      },
      scaleLabel: {
        color: "#BE9199",
        fontSize: 12,
        paddingTop: "6px",
        fontFamily: "ABCRepro",
        fontWeight: "bold",
      },
    },
    gui: {
      empty: true,
      contextMenu: {
        button: {
          visible: false,
        },
      },
    },
    animation: {
      visible: false,
    },
    hoverState: {
      backgroundColor: "none",
      shadowAlpha: 0.1,
      shadowDistance: 0,
      shadow: true,
      shadowColor: "#369",
    },
    plot: {
      barWidth: "16px",
      backgroundColor: "#0A0403",
      rules: [
        {
          rule: "%i === %N-1",
          backgroundColor: "#0A0403",
        },
      ],
      tooltip: {
        htmlMode: true,
        jsRule: "$silvr.charts.utils.makeTooltipLastSamples()",
        border: "0",
        padding: "-6px 16px -8px 16px",
        backgroundColor: "black",
        placement: "horizontal",
        borderRadius: 5,
      },
      height: "100%",
    },
    plotarea: {
      backgroundColor: "#F2EEE3",
      margin: 0,
    },
    backgroundColor: "#F2EEE3",
    series: [
      {
        values: repaymentChartData.AMOUNT,
        mediaRules: [
          {
            minWidth: 128,
            values: repaymentChartData.AMOUNT.slice(-4),
          },
          {
            minWidth: 220,
            values: repaymentChartData.AMOUNT,
          },
        ],
      },
    ],
  };

  window.zingchart.render({
    id: "graph-last-samples",
    data: configGraphLastSamples,
    height: "100%",
    width: "100%",
  });
};

export { latestRepaymentsChart, makeTooltipLastSamples };
