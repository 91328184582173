/* ----------------------------------------*\
  Main entry point
\*---------------------------------------- */

import {
  whenLibAvailable,
  downloadFile,
  loadJsonScript,
  loadScript,
  getCookieValue,
  normalizeString,
  formatCurrency,
} from "./utils.js";

import {
  loadChatScript,
  prepareChat,
  loadChatWidget,
  openChatWidget,
} from "./hubspot.js";

import { initSentry, captureException } from "./sentry.js";

import installOptico from "./optico.js";

import {
  prepareAdsRevenueChartData,
  getAdsRevenueChartConfig,
} from "./charts/client-dashboard-sales-expenses";

import {
  latestRepaymentsChart,
  makeTooltipLastSamples,
} from "./charts/client-dashboard-latest-repayments";

const $silvr = window.$silvr || {
  charts: {
    utils: {
      makeTooltipLastSamples,
    },
    dashboard: {
      prepareAdsRevenueChartData,
      getAdsRevenueChartConfig,
      latestRepaymentsChart,
    },
  },
  utils: {
    whenLibAvailable,
    downloadFile,
    initSentry,
    captureException,
    loadJsonScript,
    loadScript,
    getCookieValue,
    normalizeString,
    formatCurrency,
  },
  hubspot: {
    loadChatScript,
    prepareChat,
    loadChatWidget,
    openChatWidget,
  },
};

window.$silvr = $silvr;
installOptico();

document.addEventListener("htmx:pushedIntoHistory", function (event) {
  /* Treat htmx page loads as views in Google Analytics */
  /* global ga */
  if (typeof ga === "function") {
    ga("create", "UA-165583521-2", "auto");
    ga("set", "location", window.location.toString());
    ga("send", "pageview");
  }
});
